
export default function isEmpty(value: unknown){

  if (typeof value === 'boolean') {
    return false
  }

  if (!value) {
    return true
  }
  if (Array.isArray(value) || typeof value === 'string') {
    return !value.length
  }
  if (typeof value === 'object') {

    if (value.constructor === File) {
      return (value as File)?.size === 0
    }

    try {
      return Object.keys(value).length === 0
    } catch (e) {
      console.log(e)
    }

    return JSON.stringify(value) === '{}'
  }

  if (typeof value === 'function') {
    return false
  }

  return false
}
