import React from 'react'
import { useLoaderData } from 'react-router-dom'

import Block from 'components/block'
import { Page } from 'types/page'

export default function SlugPage() {
  const page = useLoaderData<Page>()
  return (
    <>
      {page?.page_blocks?.map(block => (
        <Block
          key={block.id}
          block={block} />
      ))}
    </>
  )
}
