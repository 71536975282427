import * as React from 'react'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'

import theme from './theme.base'

export default function CustomStyles({ children }: {children: React.ReactNode}) {

  return (
    <ThemeProvider
      defaultMode={'light'}
      theme={theme}
    >
      <Box
        sx={{
          backgroundColor: 'background.default'
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  )
}
