import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import RootPage from 'pages'
import { RecoilRoot } from 'recoil'

import Theme from 'modules/theme'

import './index.css'
import '@fontsource/nunito/300.css'
import '@fontsource/nunito/400.css'
import '@fontsource/nunito/500.css'
import '@fontsource/nunito/700.css'

import HomePage, { homePageLoader } from './pages/home'
import SlugPage, { slugPageLoader } from './pages/slug-page'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage/>,
    children: [
      {
        path: ':slug',
        Component: SlugPage,
        loader: slugPageLoader
      },
      {
        path: '',
        Component: HomePage,
        loader: homePageLoader
      },
      {
        path: '*',
        element: <Navigate
          to={'/'}
          replace />
      }
    ]
  }
])

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Theme>
        <RouterProvider router={router} />
      </Theme>
    </RecoilRoot>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
