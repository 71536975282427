import * as React from 'react'
import Container from '@mui/material/Container'
import GlobalStyles from '@mui/material/GlobalStyles'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import BlockTheme from 'components/block/block-theme'
import rest from 'modules/rest'
import { Component, HeaderComponent } from 'types/component'
import isEmpty from 'utils/isEmpty'

export default function Header() {
  const [header, setHeader] = React.useState<HeaderComponent>({})

  React.useEffect(() => {
    rest.components.get('Header').then((response: Component<HeaderComponent>) => {
      setHeader(response?.data)
    })
  }, [])

  if (isEmpty(header)) {
    return null
  }

  return (
    <BlockTheme
      background={header.background}
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 1299,
        width: '100%'
      }}>

      <Container
        maxWidth={'lg'}
        sx={{
          height: 'var(--Header-height, 0)',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>

        <GlobalStyles
          styles={{
            ':root': {
              '--Header-height': '52px'
            }
          }}
        />

        <Stack
          alignItems={'center'}
          direction={'row'}>
          <Link href={'/home'}>
            <Typography variant={'h5'}>
              {header?.site_name}
            </Typography>
          </Link>
        </Stack>

        <Stack
          direction={'row'}
          gap={1}
          py={2}
          sx={{ zIndex: 1 }}
        >
          {header?.links?.map((link) => (
            <Link
              key={link.id}
              href={link.type ==='external' ?link.link : link.page?.url_path}>
              {link.title}
            </Link>
          ))}
        </Stack>
      </Container>
    </BlockTheme>
  )
}
