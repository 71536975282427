import { LoaderFunctionArgs } from 'react-router-dom'

import rest from 'modules/rest'

export async function slugPageLoader({ params: { slug } }: LoaderFunctionArgs<string>) {
  if (!slug) {
    return {}
  }

  return await rest.pages.get(slug)
}

export { default } from './slug.page'
