import { HeroBlock, PageBlock } from 'types/page'

import Hero from './hero'

interface BlockProps {
  block: PageBlock<unknown>
}

function Block({ block }: BlockProps) {

  switch (block.block_name) {
  case 'Hero':
    return (<Hero data={block.data as HeroBlock} />)
  default:
    return null
  }
}

export default Block
