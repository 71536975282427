import React from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useHref
} from 'react-router-dom'
import { buttonClasses } from '@mui/material/Button'
import { createTheme } from '@mui/material/styles'

export const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & {
  href: RouterLinkProps['to']
}>((props, ref) => {
  const isExternal = typeof props.href === 'string' && props.href.startsWith('http')
  const to = useHref(props.href)

  return (
    <RouterLink
      ref={ref}
      to={isExternal ? props.href : to}
      {...props} />
  )
})

export default createTheme({
  colorSchemes: {
    dark: {
      palette: {
        background: {
          default: '#22272e'
        }
      }
    }
  },

  typography: {
    fontFamily: '\'Nunito\'',

    body1: {
      fontWeight: 600
    }
  },

  components: {
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...ownerState.size === 'medium' && {
            [`.${buttonClasses.startIcon}`]: {
              marginLeft: 0
            }
          }
        }),
        sizeMedium: {
          height: '40px'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        underline: 'none',
        variant: 'body2'
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {

        }
      }
    }
  }
})
