import Typography from '@mui/material/Typography'

import { HeroBlock } from 'types/page'

import BlockTheme from '../block-theme'

interface HeroProps {
  data: HeroBlock
}

function Hero({ data }: HeroProps) {
  console.log('data', data)
  return (
    <BlockTheme background={data.background}>
      <Typography variant={'h2'}>
        {data.title}
      </Typography>
    </BlockTheme>
  )
}

export default Hero
