import React from 'react'
import Box from '@mui/material/Box'
import { styled, Theme } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Property } from 'csstype'

import { BlockDataBase } from 'types/base'
import get from 'utils/get'

const StyledBox = styled(Box)<Pick<BlockDataBase, 'background'>>(({ theme, background }) => {

  let backgroundColor = get(theme.palette as Record<string, any>, background as string, theme.palette.common.white) as Property.BackgroundColor
  if (background === 'light') {
    backgroundColor = theme.palette.grey[100]
  } else if (background === 'dark') {
    backgroundColor = theme.palette.grey[900]
  }

  const isDarkBackground = background?.includes('dark') || background === 'common.black'

  return {
    backgroundColor,
    [`.${typographyClasses.root}`]: {
      color: isDarkBackground ? theme.palette.grey[100] : theme.palette.grey[900]
    }
  }
})

interface BlockThemeProps extends Pick<BlockDataBase, 'background'> {
  sx?: SxProps<Theme>
}

function BlockTheme({ background = 'common.white', sx, children }: React.PropsWithChildren<BlockThemeProps>) {

  return (
    <StyledBox
      background={background}
      sx={sx}>
      {children}
    </StyledBox>
  )
}

export default BlockTheme
