import React from 'react'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'

import rest from 'modules/rest/rest'
import { Component, FooterComponent } from 'types/component'
import isEmpty from 'utils/isEmpty'

import BlockTheme from '../block/block-theme'

export default function Footer() {
  const [footer, setFooter] = React.useState<FooterComponent>({})

  React.useEffect(() => {
    rest.components.get('Footer').then((response: Component<FooterComponent>) => {
      setFooter(response?.data)
    })
  }, [])

  if (isEmpty(footer)) {
    return null
  }

  return (
    <BlockTheme
      background={footer.background}
      sx={{ zIndex: 1 }}
    >
      <Divider />
      <Container maxWidth={'lg'}>
        <Stack
          direction={'row'}
          gap={1}
          py={2}
          sx={{ zIndex: 1 }}
        >
          {footer?.site_name}
          {footer?.links?.map((link) => (
            <Link
              key={link.id}
              href={link.type ==='external' ?link.link : link.page?.url_path}>
              {link.title}
            </Link>
          ))}
        </Stack>
      </Container>
    </BlockTheme>
  )
}
