import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

import Footer from 'components/footer'
import Header from 'components/header'
import Loading from 'components/loading'
import i18n from 'modules/i18n'

export default function RootPage() {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    Promise.all([
      i18n
    ]).finally(() => {
      setLoading(false)
    })

  }, [])

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Box
      sx={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.default',
        paddingTop: 'var(--Header-height, 0)'
      }}>
      <Header/>
      <Outlet/>
      <Box flexGrow={1}/>
      <Footer/>
    </Box>
  )
}
